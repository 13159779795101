.secInfo {
  box-shadow: 0 0px 15px 0 rgb(0 0 0 / 15%);
  padding: 15px 0;
}
.imgCarro {
  width: auto;
  padding: 0;
}
.imgCarro img {
  width: 70px;
}
.tittleCarro {
  text-transform: uppercase;
  color: #228cc0;
  font-size: 17px;
  font-weight: 700;
}
.subTittleCarro {
  text-transform: capitalize;
  color: #4a4a4a;
  font-size: 13px;
}

@media only screen and (max-width: 991px) {
  .marginBottom {
    margin-bottom: 5px;
  }
  .tittleCarro {
    font-size: 15px;
  }
}
